.home-boxes{

    .box-wrapper{

        &:nth-child(2) {
            margin: 8px 0;
            @include breakpoint(medium) {
                margin: 0;
                .box{
                    margin: 0 8px;
                }
            }
        }

        .box{
            background-color: #fff;
            text-align: center;
            padding: 15px;
            height: 100%;

            @include breakpoint(large){
                padding: 25px 25px;
            }

            .box-header{
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: contain;
                color: inherit;
                font-weight: 700;
                margin: 0;
                position: relative;
                margin-bottom: .5em;
                span {
                    position: relative;
                    z-index: 1;
                }
                .colour {
                    display: none;
                    line-height: 1;
                    z-index: 0;
                    left: -10px;
                    top: -10px;
                    //transform: translate(-50%, -50%);
                    position: absolute;
                    svg {
                        display: block;
                    }
                }
            }

            img{
                margin: 20px 0;
                @include breakpoint(medium down){
                    max-height: 15px;
                    margin: 15px 0;
                }
            }

            p{
                color: #424f0b;
                min-height: 48px;
                font-weight: 500;
                font-size: rem-calc(16);
            }
        }
    }
}


.featuredStory {
    border-bottom: 11px solid;
    color: #fff;
    .story-content {
        padding-top: 50px;
        background: rgba(#001b31, .8);
    }

    h2 {
        font-size: rem-calc(30);
        font-weight:bold;
        a:hover {
            color: $primary-color;
        }
    }

    p.date {
        font-weight: 600;
        margin-bottom: .6em;
    }

    .wrapper {
        padding: 20px;
    }

    .more {
        text-align: right;
        a {
            border-radius: 100px;
            text-align: center;
            padding: 0;
            line-height: 40px;
            width: 40px;
            height: 40px;
            font-size: 1.2em;
            &:before {
                margin-right: 0;
            }
        }
    }

}

.more-content {

    color: #fff;

    img {
        display: block;
    }

    a {
        color: #fff;
        &:hover {
            opacity: .8;
        }
    }

    h2 {
        font-size: rem-calc(21);
        font-weight: bold;
    }
    
    p {
        font-size: 1rem;
    }

    .wrapper {
        padding: 10px 15px;
        *:last-child {
            margin-bottom: 0;
        }
    }
}


.home-banner-text {

    background: rgba(0, 27, 49, 1);
    padding-bottom: 50px !important;
    transform: translateX(1px);

    @include breakpoint(medium) {
        background: rgba(0, 27, 49, 0.8);
    }

    @include breakpoint(xlarge) {
        transform: translateX(41px);
    }

    p {
        font-weight: 400;
        line-height: 1.4;
        font-size: 1.1rem;
        @include breakpoint(medium) {
            font-size: 1.1rem;
        }
        @include breakpoint(large) {
            font-size: 1.2rem;
        }
        @include breakpoint(xlarge) {
            font-size: 1.3rem;
            padding: 10px 15px;
        }
    }
}

.home-page .banner {

    @include breakpoint(small only) {
        background: rgba(0, 27, 49, 1);
        .slide {
            background-image: none !important;
        }
    }

    .slick-prev,
    .slick-next {
        top: auto;
        position: absolute;
        background: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjgxIiB2aWV3Qm94PSIwIDAgNDMgODEiIHdpZHRoPSI0MyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMi43OSA4MS0yLjc5LTIuODIgMzcuNDEtMzcuNjgtMzcuNDEtMzcuNjggMi43OS0yLjgyIDQwLjIxIDQwLjV6IiBmaWxsPSIjZmZmIi8+PC9zdmc+") no-repeat center center;
        background-size: contain;
        height: 40px;
        width: 21px;
        bottom: 15px;

        @include breakpoint(medium) {
            bottom: 40px;
            height: 81px;
            width: 43px;
        }

        &:before{
            content: none;
        }
    }

    .slick-prev {
        left: 15px;
        @include breakpoint(medium) {
            left: 30px;
        }
        transform: rotate(180deg);
    }

    .slick-next {
        right: 15px;
        @include breakpoint(medium) {
            right: 30px;
        }
    }

}