$print-hrefs: false;

@include foundation-global-styles;
//@include foundation-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
 @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
@include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

@import "components/_content";

::-moz-selection {
    background: #94bef1;
    text-shadow: none;
    color: rgba(0, 0, 0, .66);
}

::selection {
    background: #94bef1;
    text-shadow: none;
    color: rgba(0, 0, 0, .66);
}


html[data-whatinput=keyboard] {
    a[href],
    area[href],
    input:not([disabled]),
    select:not([disabled]),
    textarea:not([disabled]),
    button:not([disabled]),
    iframe,
    [tabindex],
    [contentEditable=true] {
        transition: none;
        &:not([tabindex='-1']) {
            &:focus {
                outline-width: 1px !important;
                outline-style: solid !important;
                outline-color: $primary-color;
                box-shadow: 0 0 2px $primary-color, 0 0 6px $primary-color, 0 0 10px $primary-color !important;
                z-index: 1;
            }
        }
    }
}


$placeholders: (
        '::-webkit-input-placeholder',
        '::-moz-placeholder',
        ':-ms-input-placeholder',
        ':-moz-placeholder',
        '::placeholder'
);

input, textarea {
    @each $placeholder in $placeholders {
        &#{$placeholder} {
            color: $black;
            opacity: .50;
        }
    }
    &:focus {
        @each $placeholder in $placeholders {
            &#{$placeholder} {
                opacity: .75;
            }
        }
    }
}

select, input {
    height: 2.93125rem !important;
}

select {
    margin-bottom: 0;
    width: auto;
}

textarea {
    resize: vertical;
}

button, input, optgroup, select, textarea {
    font-family: inherit;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.clearfix {
    &:before {
        content: " ";
        display: table;
    }
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

@media print {
    header, footer, aside, embed, object, iframe,
    .banner, #admin_bar_primary, .block-video, .type-slideshow, [class^="icon-"]:before, [class*=" icon-"]:before {
        display: none !important;
    }
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    width: auto;
    margin-right: 0;
    margin-left: 0;
    .button & {
        margin-right: .3em;
    }
}

.button[class^="icon-"]:before,
.button[class*=" icon-"]:before {
    margin-right: .3em;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.body-wrapper {
    overflow: hidden;
}

a, button, .button {
    transition: all 200ms linear;
    [data-whatintent="touch"] &,
    [data-whatintent="keyboard"] & {
        transition: none;
    }
}
a {
    h1, h2, h3, h4, h5, h6 {
        transition: all 200ms linear;
        [data-whatintent="touch"] &,
        [data-whatintent="keyboard"] & {
            transition: none;
        }
    }
}

$header-margin-top: .2em !default;
$header-margin-bottom: .5em !default;

h1 {
    font-weight: 700;
}

h2 {
    font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {

    margin-bottom: $header-margin-bottom;
    margin-top: $header-margin-top;

    a {
        color: inherit;
        display: block;
        &:hover {
            color: $anchor-color-hover;
        }
    }
}

dl {

    dt {
        font-size: 1.1em;
        margin-bottom: .25em;
        margin-top: .66em;
        line-height: 1.25;
    }

    dd {
        padding-bottom: 1em;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

$increaseFactor: 1.15;

@include breakpoint(large) {
    blockquote {
        font-size: 1rem;
    }
    ul, ol, dl, p {
        font-size: 1rem*$increaseFactor;
    }
    p.lead {
        font-weight: 800;
        font-size: 1rem*$increaseFactor*$increaseFactor;
    }
}

@include breakpoint(xlarge) {
    blockquote {
        font-size: 1rem*$increaseFactor;
    }
    ul, ol, dl, p {
        font-size: 1rem*$increaseFactor*$increaseFactor;
    }
    p.lead {
        font-size: 1rem*$increaseFactor*$increaseFactor*$increaseFactor;
    }
}

/* Slick Arrows */
.slick-slider {

    .slick-slide {
        position: relative;
    }

    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        z-index: 1;
        cursor: pointer;
        top: 50%;
        text-align: center;
        line-height: 50px;
        height: 34px;
        margin-top: -17px;
        width: 23px;
        padding: 0;
        border: none;
        font-size: 2rem;
        text-indent: -999px;
        overflow: hidden;
        background: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjM0IiB2aWV3Qm94PSIwIDAgMjMgMzQiIHdpZHRoPSIyMyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMCAxN2EyNSAyNSAwIDAgMCA2LjYgMTdsMTYuMDctMTYuMjFhMS4xMSAxLjExIDAgMCAwIDAtMS41OGwtMTYuMDctMTYuMjFhMjUgMjUgMCAwIDAgLTYuNiAxN3oiIGZpbGw9IiNmZjZjMGUiLz48L3N2Zz4=") no-repeat center center;

        &:before {
            margin-right: 0;
        }

        &.slick-disabled {
            opacity: 0.33;
            pointer-events: none;
        }
    }
    .slick-prev {
        transform: rotate(180deg);
        left: -30px;
    }
    .slick-next {
        right: -30px;
    }
}

$increaseFactor: 1.1;

.callout {

    p {
        font-size: 1rem;
        line-height: 1.4;
        color: #fff;
        margin-bottom: .66em;
        @include breakpoint(large) {
            font-size: 1rem*$increaseFactor;
        }
        @include breakpoint(xlarge) {
            font-size: 1rem*$increaseFactor*$increaseFactor;
        }
    }

    .button {
        background: rgba(#000, .2);
    }

    &.small p {
        font-size: .9rem;
        line-height: 1.3;
        margin-bottom: .4em;
    }
    &.large p {
        font-size: 1.16rem;
        line-height: 1.5;
        margin-bottom: 1em;
    }
    p:last-child {
        margin-bottom: 0;
    }
    &.aquaDka {
        background: #36b0c9;
        border-color: darken(#36b0c9, 8%);
    }
    &.greenIntyalheme {
        background: #768838;
        border-color: darken(#768838, 8%);
    }
    &.redIntyalheme {
        background: #c15527;
        border-color: darken(#c15527, 8%);
    }
    &.redCodesForLife {
        background: #993333;
        border-color: darken(#993333, 8%);
    }
}

hr {
    &.primary {
        border-bottom-color: $primary-color;
    }
    &.secondary {
        border-bottom-color: $secondary-color;
    }
    &.success {
        border-bottom-color: $success-color;
    }
    &.warning {
        border-bottom-color: $warning-color;
    }
    &.alert {
        border-bottom-color: $alert-color;
    }
    &.aquaDka {
        border-bottom-color: #36b0c9;
    }
    &.greenIntyalheme {
        border-bottom-color: #768838;
    }
    &.redIntyalheme {
        border-bottom-color: #c15527;
    }
    &.redCodesForLife {
        border-bottom-color: #993333;
    }
}

/* AM Forms defaults */
form.form {

    em,
    .form__note {
        font-style: normal;
        font-size: .8rem;
        opacity: .7;
        line-height: 1.1;
        display: block;
    }

    .form__note {
        margin: 1em 0;
    }

    input[type=checkbox],
    input[type=radio] {
        margin-bottom: 0;
        & + label {
            display: inline;
            color: inherit;
            font-size: 15px;
            line-height: 1.1;
        }
    }

    .type-radiobuttons,
    .type-checkboxes {
        div div {
            margin-bottom: 10px;
        }
    }
}

table.responsive {

    @media (max-width: 1024px) {

        width: 100%;
        margin: 0;
        padding: 0;
        border-collapse: collapse;
        border-spacing: 0;
        border: 0;

        thead {
            display: none;
        }

        tr {
            display: block;
            border-bottom: 2px solid #ddd !important;
            &:last-child {
                margin-bottom: 0;
                border-bottom: none !important;
            }
        }

        td {
            display: block;
            text-align: right;
            border-bottom: 1px solid #ddd;

            &:last-child {
                border-bottom: 0;
            }

            &:before {
                content: attr(data-rt-label);
                float: left;
                font-weight: bold;
            }
        }
    }
}

$thin-spacing: 8px;
.thin-grid {
    &.grid-padding-x {
        margin-right: -$thin-spacing/2;
        margin-left: -$thin-spacing/2;
        > .cell {
            padding-left: $thin-spacing/2;
            padding-right: $thin-spacing/2;
        }
    }
    &.grid-padding-y {
        margin-top: -$thin-spacing/2;
        margin-bottom: -$thin-spacing/2;
        > .cell {
            padding-top: $thin-spacing/2;
            padding-bottom: $thin-spacing/2;
        }
    }
}

.card {
    overflow: visible;
}


.pagination {
    text-align: center;
    font-weight: bold;

    margin-bottom: 20px;
    margin-top: 20px;
    @include breakpoint(large) {
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .pagination-previous{
        .disabled::before,
        a::before {
            margin-right: 0;
        }
    }

    .pagination-next {
        a::after,
        &.disabled::after {
            margin-left: 0rem;
        }
    }

    .disabled, .current, a, button {
        text-align: center;
        min-width: 53px;
        border: 2px solid #fff;
    }

    .current {
        border-color: $primary-color;
    }

    a {
        &:hover {
            color: $primary-color;
            border-color: $primary-color;
        }
    }
}
