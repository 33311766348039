
.services-list {

    h2 {
        font-weight: bold;
        font-size: rem-calc(30);
        padding: 0 20px;
    }

    p {
        padding: 0 10px;
        font-weight: 300;
        font-size: 1.166em;
        line-height: 1.25;
    }

    a {
        background: #fff;
        text-align: center;
        display: block;
        color: $body-font-color;
        padding: 30px 0;
        &:hover {
            background: rgba(#102, .03);
        }
    }

    .service-colour-bar {
        height: 25px;
    }
}

.services a:hover {
    color: #000 !important;
}

.service-colour-bar {
    height: 15px;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
}

.icon-combo {
    position: relative;
    width: 160px;
    margin: 0 auto;
    .colour {
        position: absolute;
        right: 0;
        top: 0;
    }

    .icon {
        text-align: center;
        position: relative;
        z-index: 1;
        padding-right: 25px;
        padding-top: 20px;
        min-height: 125px;
    }
}


.entry-type-services {

    @include breakpoint(large) {
        .page-container {
            padding-top: 30px;
            margin-top: -7em;
        }
    }

    .page-title {
        margin-top: 0;
        text-align: center;
    }

    .service-colour-bar {
         margin-bottom: 3em;
    }

    @include breakpoint(medium) {
        .icon-combo {
            margin-top: 7px;
            float: left;
            margin-right: 40px;
        }
    }
}