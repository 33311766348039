.stories {
    margin-top: 30px;
    margin-bottom: 30px;
}

.item.story {

    border-top: 11px solid #cecece;
    font-weight: 600;
    text-align: left;
    background: #fff;
    .services{
        font-weight: 400;
    }
    h2, h3 {
        margin: .33em 0 .3em;
        font-size: rem-calc(20);
        a {
            color: $body-font-color;
            &:hover {
                color: $secondary-color;
            }
        }
    }

    p {
        font-size: .95rem;
        line-height: 1.3;
        margin-bottom: .66em;
        font-weight: 300;

        a{
            font-weight: 600;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .date {
        color: $header-color;
        margin-bottom: 0.4em;
        font-weight: 600;
    }

    .wrapper {
        margin: 15px;
    }

    .meta {
        color: $header-color;
        font-size: rem-calc(14);
        b {
            text-transform: uppercase;
        }
    }

    .img {
        display: block;
    }
}

.filters {

    select {
        display: block;
        width: 100%;
        margin-bottom: 15px;
    }
    button {
        height: 2.9rem;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 2.9rem;
        text-align: center;
    }

    p {
        margin: 0;
    }
}
