.content-blocks {

    $horizontal-spacing: 20px;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    a.magnify {
        text-indent: -999px;
        overflow: hidden;
        color: #fff;
        font-size: 2.5rem;
        text-align: center;
        width: 40px;
        height: 43px;
        line-height: 40px;
        right: 10px;
        top: 10px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
        position: absolute;
        display: block;
        opacity: .6666;
        &:before {
            text-indent: 999px;
        }
        &:hover {
            opacity: 1;
            text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        }
    }

    &, .block {

        margin-bottom: 15px;
        margin-top: 15px;

        @media (min-width: 1024px) {
            margin-bottom: 25px;
            margin-top: 25px;
        }

        @media (min-width: 1280px) {
            margin-bottom: 35px;
            margin-top: 35px;
        }
    }

    .block:last-child {
        margin-bottom: 0;
    }

    &:first-child {
        margin-top: -15px;

        @media (min-width: 1024px) {
            margin-top: -25px;
        }

        @media (min-width: 1280px) {
            margin-top: -35px;
        }
    }

    .block-text {
        up, ol, p, table {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .block-gallery {

        .thumbnail {
            margin-bottom: 0;
        }

        .type-slideshow {
            position: relative;
            p {
                min-height: 50px;
                position: absolute;
                bottom: 0;
                right: 100px;
                padding: 6px 10px 8px;
                background: rgba(0, 0, 0, .75);
                margin: 0;
                color: #fff;
                font-weight: normal;
                font-size: rem-calc(14);
                line-height: 1.285714286;
            }
        }
    }

    .block-gallery,
    .block-slideshow {
        clear: both;
    }

    .block-image {

        .arrow {
            position: absolute;
            top: 12%;
            left: 0;
            margin-left: -10px;

            svg {
                height: 55px;
                width: 37px;
            }

            @include breakpoint(large) {
                margin-left: -21px;
                svg {
                    height: 110px;
                    width: 74px;
                }
            }

        }

        .img {
            position: relative;
        }

        p {
            line-height: 1.25;
            font-size: .9rem;
            color: smart-scale($black, 30%);
        }
    }

    .block-map {
        a {
            display: block;
        }
    }

    .block-callout {
    }

    .block-callout .callout {
        border: 0;
    }

    .block-callout .position-full .callout {
        border: none;
        padding: 0;
        padding-bottom: 15px;
        padding-top: 15px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1020px;

        @media (min-width: 1024px) {
            padding-bottom: 25px;
            padding-top: 25px;
        }

        @media (min-width: 1280px) {
            padding-bottom: 35px;
            padding-top: 35px;
        }

        &:before {
            content: '';
            background: inherit;
            top: 0;
            bottom: 0;
            left: -90px;
            right: -90px;
            position: absolute;
        }
        p {
            position: relative;
        }
    }

    .block-embeddedCode {
        iframe, object, embed {
            max-width: 100%;
        }
    }

    .block-audioPlayer,
    .block-callout,
    .block-map,
    .block-embeddedCode,
    .block-media,
    .block-image {

        @media (min-width: 641px) {

            clear: both;

            & > div {
                width: 50%;
                margin-bottom: 15px;

                @media (min-width: 1024px) {
                    margin-bottom: 25px;
                }

                @media (min-width: 1280px) {
                    margin-bottom: 35px;
                }

            }

            .position-left {
                float: left;
                padding-right: $horizontal-spacing;
                padding-top: 6px;
            }
            .position-right {
                float: right;
                padding-left: $horizontal-spacing;
                padding-top: 6px;
            }
            .position-center {
                margin-left: auto;
                margin-right: auto;
                min-width: 320px;
            }
            .position-full {
                width: auto;
            }
        }

        & > div {

            @media (min-width: 1024px) {
                width: 45%;
                &.position-center {
                    min-width: 60%;
                }
            }

        }
    }

    .block-submenu{

        .submenu{
            margin-left: -10px!important;
            margin-right: -10px!important;
        }
        .menu-image{
            min-height: 200px;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 20px;
            transition: 200ms ease opacity;
        }
        a{
            text-align: center;
            &:hover, &.active{
                .menu-image{
                    opacity: 0.7;
                }
                color: $secondary-color;
            }
            &.active{
                cursor: default;
            }
        }
    }

}

.twitter-tweet {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}