.banner-wrapper {
    position: relative;
    z-index: 0;
    background: $secondary-color;
    color: #fff;
    font-size: 1rem;

    h2 {
        font-size: rem-calc(24);
        font-weight: bold;
    }

    p {
        font-size: 1rem;
        margin-bottom: .85em;
        line-height: 1.2;
        @include breakpoint(large) {
            font-size: 1.2rem;
        }
        a {
            color: inherit;
            &:hover {
                color: $primary-color;
            }
        }
    }

    .map {
        display: block;
    }

    .contact {
        align-items: center;
        padding: 15px 0 35px;
        @include breakpoint(medium) {
            padding: 30px 0 50px;
        }
        @include breakpoint(large) {
            padding: 40px 0 60px;
        }
        @include breakpoint(xlarge) {
            padding: 50px 0 70px;
        }
    }

    .not-home-page & .slider {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    @include breakpoint(medium) {
        .home-page & .slider {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
}

.banner {

    position: relative;

    &, .slick-slider {
        max-width: 1280px;
        margin: 0 auto;
    }

    overflow: hidden;
    height: 40vh;
    max-height: 600px;

    .home-page & {
        @include breakpoint(medium) {
            min-height: 470px;
        }
    }

    &, .slide {
        background: center center no-repeat;
        background-size: cover;
    }

    .grid-container,
    .grid-x,
    .slide,
    .slick-slide,
    .slick-slide > div,
    .slick-list,
    .slick-track {
        height: 100%;
    }
}