
footer {

    color: #fff;

    h5 {
        font-weight: 700;
    }

    p {
        margin-bottom: 0;
    }

    a {
        color: #fff;
    }

    p, ul, ol {
        font-size: rem-calc(16);
    }

    .useful-info {
        background: $primary-color;
        a:focus {
            outline-color: $secondary-color !important;
        }
        a:hover {
            color: $secondary-color;
        }

    }

    .legal-stuff {
        background: $secondary-color;
        a:focus,
        a:hover {
            color: $primary-color;
        }
    }

    .social-media a {
        color: $primary-color !important;
        background: #fff;
        border-color: #fff;
        &:before {
            border-color: $primary-color;
        }
    }

    .subscribe {
        input {
            border: none; margin-bottom: 5px;
            font-size: inherit;
            max-width: 220px;
            height: 2.231rem !important;
            &:focus {
                border: none;
                outline: 2px solid $secondary-color;
            }

        }
        button {
            font-weight: bold; padding:5px; margin-left: -5px;
        }
    }
}

