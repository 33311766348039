/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 3, 2019 */
@font-face {
    font-family: 'humanist';
    src: url('bitstream_-_humanst521_bt_roman-webfont.woff2') format('woff2'),
    url('bitstream_-_humanst521_bt_roman-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'humanist';
    src: url('bitstream_-_humanst521_bt_italic-webfont.woff2') format('woff2'),
    url('bitstream_-_humanst521_bt_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'humanist';
    src: url('bitstream_-_humanst521_bt_bold-webfont.woff2') format('woff2'),
    url('bitstream_-_humanst521_bt_bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'humanist';
    src: url('bitstream_-_humanst521_bt_bold_italic-webfont.woff2') format('woff2'),
    url('bitstream_-_humanst521_bt_bold_italic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'humanist';
    src: url('bitstream_-_humanst521_xbd_bt_extra_bold-webfont.woff2') format('woff2'),
    url('bitstream_-_humanst521_xbd_bt_extra_bold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

/*@font-face {*/
    /*font-family: 'humanst521_lt_btlight_italic';*/
    /*src: url('bitstream_-_humanst521_lt_bt_light_italic-webfont.woff2') format('woff2'),*/
         /*url('bitstream_-_humanst521_lt_bt_light_italic-webfont.woff') format('woff');*/
    /*font-weight: normal;*/
    /*font-style: normal;*/

/*}*/

/*@font-face {*/
    /*font-family: 'humanst521_lt_btlight';*/
    /*src: url('bitstream_-_humanst521_lt_bt_light-webfont.woff2') format('woff2'),*/
         /*url('bitstream_-_humanst521_lt_bt_light-webfont.woff') format('woff');*/
    /*font-weight: normal;*/
    /*font-style: normal;*/

/*}*/