main {

    position: relative;
    z-index: 1;
    padding-top: 20px;

    &:before {
        top: -20px;
        left: 0;
        right: 0;
        content: '';
        height: 40px;
        background: #f5a800;
        opacity: .8;
        position: absolute;
    }

    h1.page-title {
        text-align: center;
        @include breakpoint(large) {
            margin-top: -40px;
        }
        span {
            background: #fff;
            padding: .33em .5em;
            display: inline-block;
            min-width: 250px;
            @include breakpoint(medium) {
                min-width: 600px;
            }
        }
    }

    h2.page-title {
        font-weight: 600;
        text-align: center;
        span {
            font-size: 1.1em;
            display: block;
            padding-top: .333em;
        }
        @include breakpoint(large) {
            margin-top: -8px;
        }
    }

    .page-container {

        position: relative;
        padding-top: 15px;
        overflow: hidden;

        @include breakpoint(medium) {
            padding-top: 40px;
        }

        @include breakpoint(large) {
            padding-top: 50px;
        }

        .content-wrapper {
            @include breakpoint(large) {
                padding: 0 20px 20px;
            }
            @include breakpoint(xlarge) {
                padding: 0 70px 20px;
            }
        }
    }
}

aside {
    margin-bottom: 40px;

    & > .menu {
        margin-top: -5px;
        transform: translateX(-15px);
    }
}

.social-media {

    a {
        background-color: $primary-color;
        display: inline-block;
        margin-right: .33em;
        color: #fff;
        border-radius: 100px;
        border: 2px solid $primary-color;
        line-height: 1.56em;

        &:before {
            border-radius: 100px;
            height: 1.66em;
            width: 1.66em;
            line-height: 1.56em;
            font-size: 1.2em;
            text-align: center;
            border: 1px solid #fff;
        }

        &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
        }
    }
}

.page-container.service {

    h1.page-title {
        margin-top: 0;
        margin-bottom: 0.75em;
        padding: .15em 0 .4em;
        border-bottom: $primary-color 10px solid;
    }
}

.white-bg {
    background: #fff;
    position: relative;

    &:before, &:after {
        background: #fff;
        position: absolute;
        bottom: 0;
        top: 0;
        content: "";
        width: 2000px;
        right: 100%;
    }

    &:after {
        right: auto;
        left: 100%;
    }

}

.gray-bg {

    background: #f1f3f6;
    position: relative;

    &:before, &:after {
        background: #f1f3f6;
        position: absolute;
        bottom: 0;
        top: 0;
        content: "";
        width: 2000px;
        right: 100%;
    }

    &:after {
        right: auto;
        left: 100%;
    }

}

.grey-bg {
    background: $body-background;
}

.page-header-1 {
    z-index: 1;
    background: #fff;
    text-align: center;
    position: relative;
    &:before, &:after {
        background: #fff;
        position: absolute;
        bottom: 0;
        top: 0;
        content: "";
        width: 2000px;
        right: 100%;
    }

    &:after {
        right: auto;
        left: 100%;
    }

    h1 {
        margin-top: 0;
        margin-bottom: .8em;
        span {
            transform: translateY(0.8em);
            background: #fff;
            width: 50%;
            min-width: 250px;
            max-width: 600px;
            padding: .33em .5em;
            display: inline-block;
        }
    }
}

.page-header-1-invert {
    background: #fff;
    text-align: center;
    h1 {
        margin-top: 0.8em;
        margin-bottom: -0.8em;
        span {
            transform: translateY(-0.8em);
            background: #fff;
            width: 50%;
            min-width: 250px;
            max-width: 600px;
            padding: .33em .5em;
            display: inline-block;
        }
    }
}

.page-header-2 {
    text-align: center;
    z-index: 1;
    position: relative;
    h1 {
        margin-bottom: 0;
        margin-top: 0;
        padding-top: .66em;
        background: #fff;
        @include breakpoint(medium) {
            padding-top: 0;
            background: none;
        }
        span {
            @include breakpoint(medium) {
                padding: .33em .5em;
                transform: translateY(0.6em);
                display: inline-block;
                min-width: 250px;
                width: 50%;
                max-width: 600px;
                background: #fff;
                @include breakpoint(large) {
                    transform: translateY(0.8em);
                }
            }
        }
    }
}

.share {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin: 2em 0 !important;
    span {
        padding-top: 10px;
        display: block;
    }
}

.pagination-prev-next {

    line-height: 2;
    padding-bottom: 20px;
    padding-top: 30px;
    overflow: hidden;

    .reveal & {
        padding-bottom: 0;
        padding-top: 20px;
    }

    a {
        padding-top: 10px;
        display: block;
        float: left;
        font-weight: 500;
        max-width: 370px;
        font-size: 1.1em;
        line-height: 1.4;
        padding-right: 50px;
        &:before {
            font-size: 1.5em;
            margin-right: 0.2em;
            float: left;
        }
        &.next {
            padding-right: 0px;
            padding-left: 0px;
            float: right;
            &:before {
                float: right;
                margin-right: 0;
                margin-left: 0.2em;
            }
        }
    }
}

@mixin dkaTheme($color) {
    a:not(.button) {
        color: $color;
        &:hover {
            color: $black;
        }

    }
    .social-media {
        a {
            color: #fff;
            background-color: $color;
            border-color: $color;
            &:hover {
                background-color: $black;
                border-color: $black;
            }
        }
    }

    h2.page-title {
        color: $color;
    }
}

.theme-dk-precinct {
    @include dkaTheme($primary-color);
}

.theme-intyalheme {
    @include dkaTheme(#4c9c2e);
}

.theme-dka-solar-centre {
    @include dkaTheme(#f5a800);
}

.theme-brokerage-facilitation {
    @include dkaTheme(#a3bbc3);
}

.theme-codes-for-life-program {
    @include dkaTheme(#993333);
}

.theme-knowledge-experiences {
    @include dkaTheme(#ce0e2d);
}

.staff-list {

    & > div {
        margin: 0 15px !important; ;
    }

    h2 {
        font-size: rem-calc(19);
        margin-bottom: .33em;
    }
    p {
        line-height: 1.33;
        font-size: rem-calc(16);
        margin-bottom: 0.2em;
    }

    .wrapper {
        padding: 0 4px;
        position: relative;

        [data-whatinput=touch] &,
        &:hover {
            .overlay {
                opacity: 1;
            }
        }
    }

    .details {
        [data-whatinput=touch] & {
            display: none;
        }
        background: #fff;
        padding: 10px 15px;
    }

    .overlay {
        transition: opacity 100ms linear;
        opacity: 0;
        bottom: 0;
        left: 4px;
        right: 4px;
        position: absolute;
        color: #fff;
        padding: 10px 15px;

        [data-whatinput=touch] & {
            opacity: 1;
            bottom: auto;
            left: auto;
            right: auto;
            position: relative;
        }

        a {
            color: #fff;
            &:hover {
                color: #000;
            }
        }
    }
}

.board-list {

    h2 {
        color: $primary-color;
        margin-top: .5em;
        margin-bottom: .25em;
        font-size: rem-calc(27);
        font-weight: 700;
    }

    p {
        font-size: rem-calc(18);
        padding-right:0;
        color: $body-font-color;

        @include breakpoint(medium) {
            padding-right: 40px;
        }
        &.position {
            font-size: rem-calc(20);
            font-weight: 600;
            margin-bottom: .2em;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    .details {
        overflow: hidden;
        max-height: 0;
        transition: max-height 400ms ease-out;
        p {
            &:first-child {
                padding-top: 15px;
            }
            &:last-child {
                padding-bottom: 15px;
            }
        }
    }

    .wrapper {
        padding: 0 15px 15px ;
        @include breakpoint(medium) {
            padding: 0;
        }
    }

    .info {
        position: relative;
        &:before {
            content: "";
            height: 15px;
            width: 22px;
            position: absolute;
            right: 0px;
            top: 5px;
            @include breakpoint(medium) {
                right: 25px;
                top: 25px;
            }
            background: url('data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIyLjA2IiBoZWlnaHQ9IjE0Ljg4IiB2aWV3Qm94PSIwIDAgMjIuMDYgMTQuODgiPjx0aXRsZT5yZWQtYXJyb3c8L3RpdGxlPjxwYXRoIGQ9Ik0xMSAxNC44OGExNi4yMyAxNi4yMyAwIDAgMCAxMS00LjI3TDExLjU0LjIxYS43NC43NCAwIDAgMC0xIDBMMCAxMC42MWExNi4yMiAxNi4yMiAwIDAgMCAxMSA0LjI3eiIgZmlsbD0iI2ZmNmMwZSIvPjwvc3ZnPg==') center center no-repeat;
            background-size: contain;
            transform: rotate(180deg);
            transition: transform ease 200ms;
        }
        @include breakpoint(medium) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .board-member {
        display: block;
        background: $body-background;
        margin-bottom: 8px;
    }


    .open {
        background: none;
        .info:before {
            transform: rotate(0deg);
        }
        .details {
            max-height: none;
            @include breakpoint(medium) {
                max-height: 1000px;
            }
            @include breakpoint(large) {
                max-height: 500px;
            }
        }
    }

}